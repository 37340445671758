@import '_common';
$top-main-gap: 64px;
$dirty-top-main-gap: 56px;
$dirty-main-gap: $top-main-gap - 8;
$fixed-right-gap: 15px;
$footer-height: 90px;
// $default-transition-duration: 0.3s;
$serif: 'Times New Roman', Times, serif;
$default-font: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans',
	'Helvetica Neue', sans-serif;

:root {
	--primary-dark-text: rgba(0, 0, 0, 0.87);
	--secondary-dark-text: rgba(0, 0, 0, 0.54);
	--disabled-dark-text: rgba(0, 0, 0, 0.38);
	--divider-dark-text: rgba(0, 0, 0, 0.12);
	--primary-light-text: #fff;
	--secondary-light-text: rgba(255, 255, 255, 0.7);
	--disabled-light-text: rgba(255, 255, 255, 0.5);
	--divider-light-text: rgba(255, 255, 255, 0.12);
	--cool-dark-color: #495057;
	// --main-bg-color: #fefbfa;
	--selected-bg-color: var(--bs-orange);
	--selected-color: var(--bs-white);
	--transition-duration: 0.25s;

	// @each $screen, $size in $grid-breakpoints {
	// 	--screen-width-#{$screen}: #{$size};
	// }
}
[data-bs-theme='dark'] {
	--bs-dark-rgb: 23, 27, 31;
	--selected-bg-color: #cc6713;
	--selected-bg-color: color-mix(in oklch, var(--bs-orange), black 25%);
	// --selected-bg-color: var(--bs-warning-border-subtle);
	// --main-bg-color: var(--bs-body-bg);
}

%secondary-light-text {
	color: var(--secondary-light-text);
	// @include colors($secondary-light-text-colors);
}
.secondary-light-text {
	@extend %secondary-light-text;
}

%cool-dark-color {
	color: var(--cool-dark-color);
}
.cool-dark-color {
	@extend %cool-dark-color;
}

%default-font {
	font-family: $default-font;
}

%top-sticky {
	position: sticky;
	top: var(--sticky-top-position, $top-main-gap);
	z-index: 1;
}
%top-bottom-sticky {
	@extend %top-sticky;
	bottom: 0;
}

%bottom-left-2 {
	bottom: 0.5rem;
	left: 0.5rem;
}
.bottom-left-2 {
	@extend %bottom-left-2;
}
%bottom-right-2 {
	bottom: 0.5rem;
	right: 0.5rem;
}
.bottom-right-2 {
	@extend %bottom-right-2;
}

.top-sticky {
	@extend %top-sticky;
}
.top-bottom-sticky {
	@extend %top-bottom-sticky;
}

.top-main-gap {
	top: $top-main-gap;
}

.dirty-main-gap {
	top: $dirty-main-gap;
}

.fw-500 {
	font-weight: 500;
}

.bottom-0 {
	bottom: 0;
}

body {
	// @extend %bg-default;
	@extend %default-font;
}

#root {
	padding-top: $top-main-gap;
	// padding-top: $dirty-main-gap;
}

.mt-n1 {
	margin-top: -0.25rem !important;
}
.mt-n2 {
	margin-top: -0.5rem !important;
}
.my-n2 {
	margin-top: -0.5rem !important;
	margin-bottom: -0.5rem !important;
}
.mt-n3 {
	margin-top: -1rem !important;
}

.gray-400 {
	color: var(--bs-gray-400, gray);
}

.z-index-3 {
	z-index: 3;
}

.min-width-0 {
	min-width: 0;
}

.fs-14 {
	font-size: 14px;
}

.cursor-pointer {
	cursor: pointer;
}

.bg-default {
	background-color: var(--bs-body-bg);
	// @extend %bg-default;
}

%text-bg-selected {
	background-color: var(--selected-bg-color);
	color: var(--selected-color);
}
.text-bg-selected {
	@extend %text-bg-selected;
}

@keyframes keyframe-bounce {
	0% {
		transform: scale(0.8);
	}
	50% {
		transform: scale(1.5);
	}
	100% {
		transform: scale(0.8);
	}
}
.animation-bounce {
	animation: keyframe-bounce 2s infinite linear;
	display: inline-block;
}

@keyframes keyframes-highlight {
	0% {
		background-color: var(--bs-warning, yellow);
	}
	100% {
		background-color: var(--bs-body-bg);
	}
}
.animation-highlighted {
	animation: 0.5s linear alternate 0.1s 1 keyframes-highlight;
}

// $fi-lg-lh: 1.25em;
// .fi.fi-lg {
// 	line-height: $fi-lg-lh;
// 	width: calc($fi-lg-lh * 4 / 3);
// }
// .fi.fis.fi-lg {
// 	line-height: $fi-lg-lh;
// 	width: $fi-lg-lh;
// }

%small-text {
	font-size: 0.8em !important;
}
.small-text,
.text-small {
	@extend %small-text;
}

.header__brand-image_lg {
	width: 1.5em;
	height: 1.5em;
	margin: unset;
	margin-top: -3px;
}

.persistent-video {
	$width: 25vw;
	position: fixed;
	left: 1rem;
	bottom: 80px;
	width: $width;
	// height: $width * 0.5625;
}

%white-halo {
	box-shadow: 0 0 4px white;
}
.panel-on-image {
	@extend %white-halo;
	background-color: rgba(0, 0, 0, 0.5);
	font-size: 0.8rem;
}

.videos__list {
	display: grid;
	grid-auto-flow: row dense;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	align-items: start;
}
// .videos__card-img-container {
// 	padding-top: 56%; /* Player ratio: 100 / (1280 / 720) */
// 	overflow: hidden;
// }
// .videos__card-img {
// 	top: -17%;
// 	left: 0;
// }
.videos__card_not-processed {
	// filter: grayscale(100%);
	.videos__card-img {
		// img {
		filter: sepia(100%);
		// filter: grayscale(100%);
	}
	// opacity: 0.8;
}
// .videos__duration {
// 	position: absolute;
// 	@extend %bottom-right-2;
// }
// .videos__no {
// 	position: absolute;
// 	@extend %bottom-left-2;
// }
.videos__text {
	margin-top: -0.75rem;
	p {
		margin-bottom: 0.25em;
		overflow-wrap: anywhere;
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.highlighting-container {
	em {
		@extend %text-bg-selected;
		font-style: normal;
		font-weight: 500;
		// display: inline-block;
		padding: 0 0.25em;
		border-radius: 0.25em;
	}
}
// .videos__text {
// 	margin-top: -0.75rem;
// 	display: none;
// 	// max-height: 25vh;
// 	// overflow: hidden;
// 	// display: none;
// 	// z-index: 2;
// 	border: 1px solid rgba(0, 0, 0, 0.125);
// 	border-top: none;
// 	p {
// 		margin-bottom: 0.25em;
// 		overflow-wrap: anywhere;
// 	}
// }
// .videos__card {
// 	max-height: 295px;
// 	overflow: hidden;
// 	&:hover {
// 		overflow: visible;
// 		z-index: 1;
// 		.videos__text {
// 			display: block;
// 		}
// 	}
// }

.shadow-country-flag {
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.fi.country-flag {
	width: var(--country-flag-width, 1.53em);
	height: var(--country-flag-height, 1.1475em);
	background-size: auto;
	&.fis {
		--country-flag-width: 1em;
		--country-flag-height: 1em;
	}
}

.icon-lg {
	scale: 138%;
}
.icon-xl {
	scale: 162%;
}

.caption__image {
	width: 38vmin;
}

.not-auth {
	#root {
		background-color: #0e01ff;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23020dff' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%230326ff' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%23043eff' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%230557ff' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23066FFF' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%231c78ff' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%233282ff' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%23488cff' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%235e97ff' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%2374A3FF' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
		// background-color: #ffaa00;
		// background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23ffb100' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23ffb800' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%23ffbe00' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23ffc500' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23ffcc00' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23ffd914' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%23ffe529' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%23ffef3d' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%23fff852' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%23ffff66' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
		background-attachment: fixed;
		background-size: cover;
	}
}

.channel-image {
	width: var(--channel-img-size, 2.8em);
	height: var(--channel-img-size, 2.8em);
	border-radius: 50%;
	line-height: unset !important;
}

.is-not-valid {
	--bs-body-color: var(--bs-danger);
	// color: var(--bs-danger);
	.form-control {
		// --bs-body-color: var(--bs-danger);
		border-color: var(--bs-danger);
	}
}

.white-halo {
	box-shadow: 0 0 5px white;
}

.logout__container {
	.logout__user-name {
		max-width: 0;
		transition: all var(--transition-duration) linear 0.6s;
		opacity: 0;
	}
	&:hover {
		.logout__user-name {
			max-width: 200px;
			opacity: 1;
		}
	}
}
